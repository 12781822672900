export const routes = [{
    path: '/login',
    name: "Login",
    component: () =>
        import ('../pages/AuthPage.vue'),
    },


    {
        path: '/',
        component: () =>
            import ('../pages/MainPage.vue'),
        name: "MainPage",
        children: [
            {
                path: '', // путь по умолчанию
                component: () => import('../pages/Contact.vue'),
                name: 'ContactPage'
            },
            // {
            //     path: "/hit",
            //     component: () =>
            //         import ("../pages/HitPage.vue"),
            //     name: "hit",
            // },
            {
                path: "/prevention",
                component: () =>
                    import ("../pages/PreventionPage.vue"),
                name: "prevention",
            },
            {
                path: "/results",
                component: () =>
                    import ("../pages/Result.vue"),
                name: "ResultPage",
            },
        ]
    },



]